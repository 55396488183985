import React from 'react';
import IFirstCompilationProps from "./IFirstCompilationProps";
import IFirstCompilationState from './IFirstCompilationState';
import FirstCompilationState from "./FirstCompilationState.json";
import "./FirstCompilation.css";
import Header from "../Header/Header";
import CodiceFiscale from "codice-fiscale-js";
import wLazyForm from "@en/wlazyform";
import {ApiService} from "../../libs/ApiService";
import {ConfigInterface} from "../../interfaces/ConfigInterface";
import App from "../../App";
import NotEnabled from "../NotEnabled/NotEnabled";

export default class FirstCompilation extends React.Component<IFirstCompilationProps, IFirstCompilationState> {

  private templateToCompile:string="";
  private language:string="";
  private company:string="";
  private template:string="";
  private compiledForm:any=null;
  private compiledTemplate:string="";
  private static companyName:string="";
  private templateGeneration:boolean=false;

  constructor(props: any) {
    super(props);
    this.state = FirstCompilationState;
  }
  async componentDidMount(): Promise<void> {
    document.title="Compilazione template-wDesk"
    this.templateGeneration=(await App.getConfig()).Features.TemplateGeneration;

    try{
      this.templateToCompile=atob(window.location.search.split("template=")[1]);
    }
    catch(e){
      console.error(e);
    }
    if(this.templateGeneration){
      const dynamicServerURL:string="https://"+window.location.hostname+"/backend"
      ApiService.setServerURL(process.env.REACT_APP_BACKEND_ENDPOINT?process.env.REACT_APP_BACKEND_ENDPOINT:dynamicServerURL);
      ApiService.callToServer("utils", "getConfiguration", "v1").then((config:ConfigInterface)=>{
        FirstCompilation.companyName=config.MyExpress.Context.Tenant!=="@DEFAULT"?config.MyExpress.Context.Tenant:"Euronovate";
      }).catch(()=>{})
      FirstCompilationState.onScreen="cfInsertion";
      this.setState(FirstCompilationState);
    }
  }

  componentWillUnmount(): void {
    document.title="wDesk"
  }
  render() {

    console.log("Entrato nel render");
    return (
        <div>
          {this.templateGeneration && (<div>
            <Header logout={() => {}}></Header>
            <div className="scrollable-content mt-5 pt-3">
              {this.state.onScreen === "cfInsertion" && (
                  <div>
                    <div className="main-content pt-3">
                      <div className="form-container">
                        <h2 className="mb-3 text-center big-title">Inserire il codice fiscale</h2>
                        <form onSubmit={(e) => {
                          e.preventDefault();
                          this.sendUser(e)
                        }} id="cfInsertForm" autoComplete="off">
                          <input type="text" autoComplete="off" className="form-control big-input" id="codiceFiscale"
                                 placeholder="Codice Fiscale" minLength={16}
                                 maxLength={16} onChange={() => {
                            (document.getElementById("codiceFiscale") as HTMLInputElement).value=(document.getElementById("codiceFiscale") as HTMLInputElement).value.toUpperCase();
                            this.checkCF()
                          }} required/>
                        </form>
                      </div>
                    </div>
                    <div className="fixed-bottom footer-content">
                      <div className="container-fluid">
                        <div className="row">
                          <div className="col text-start">
                          </div>
                          <div className="col text-end">
                            <button className="btn btn-primary" disabled={this.state.disabledSendButton}
                                    onClick={() => {
                                      this.showOverlay();
                                      this.sendUser();
                                    }}>Invia
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
              )}
              {this.state.onScreen == "endingCompilation" && (
                  <div>
                    <div>
                      <h3>Grazie. La compilazione è stata completata e salvata con successo.</h3>
                      <h4>Puoi chiudere questa schermata.</h4>
                    </div>
                  </div>
              )}
              {this.state.onScreen == "templateCompilation" && (
                  <div>
                    <div className="fixed-bottom footer-content">
                      <div className="container-fluid">
                        <div className="row">
                          <div className="col text-start">
                            <button className="btn btn-secondary" onClick={(a) => {
                              this.cancelCompilation()
                            }}>Annulla
                            </button>
                          </div>
                          <div className="col text-end">
                            <button className="btn btn-primary" id="compilingFormBtn" onClick={() => {
                              this.compiledForm=(document.getElementById("generatedForm") as HTMLFormElement);
                              document.getElementById("btnSendForm")?.click();
                            }}>Invia
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
              )}
              {this.state.onScreen === "waitingForServer" && (
                  <div id="overlay">
                    <div id="overlay-content">
                      <div className="loader"></div>
                      <p className="text-light mt-3 fs-4">Caricamento...</p>
                    </div>
                  </div>)}
              {this.state.onScreen === "previewPdf" && (
                  <div>
                    <div className="main-content pt-3">
                      <div className="form-container">
                        <div className="scrollable-y bg-light p-3 pdfViewer">
                          
                          <iframe title="Compiled Template" src={`data:application/pdf;base64,${this.compiledTemplate}`} width="100%" height="100%"></iframe>
                        </div>
                      </div>
                    </div>
                    <div className="fixed-bottom footer-content">
                      <div className="container-fluid">
                        <div className="row">
                          <div className="col text-start">
                            <button className="btn btn-secondary" onClick={(a) => {
                              this.recompileForm()
                            }}>Ricompila template
                            </button>
                          </div>
                          <div className="col text-end">
                            <button className="btn btn-primary" id="confirmFormBtn" onClick={() => {
                              (document.getElementById("confirmFormBtn") as HTMLButtonElement).disabled=true;
                              this.saveCompilation();
                            }}>Conferma compilazione
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
              )}
              {(this.state.onScreen === "cfInsertion" || this.state.onScreen === "templateCompilation") && (
                  <div id="generatedFormPlaceholder" style={{visibility:"visible"}}>

                  </div>)}
              {(this.state.onScreen === "previewPdf") && (
                  <div id="generatedFormPlaceholder" style={{visibility:"hidden"}}>

                  </div>)}
            </div>
          </div>)}
          {!this.templateGeneration && (
              <NotEnabled></NotEnabled>
          )}
        </div>
    )
  }

  private async sendUser(e?: React.FormEvent<HTMLFormElement>) {
    e?.preventDefault();
    this.showOverlay();
    const userRes: {
      isUserIdentified: boolean,
      user: {
        name: string,
        surname: string,
        fiscalCode: string,
        birthDate: any,
        email: string,
        phone: any,
        birthCity: string,
        birthProvince: string,
        birthCountry: string,
        sex: string,
        AESAcceptDate: any
      },
      hasUserAlreadySignedFEA: boolean
    } = await ApiService.callToServer("anagrafica", "getUserFromCF","v1",{
      CF:this.state.taxCode,
      CompanyName:FirstCompilation.companyName
    });
    FirstCompilationState.onScreen="templateCompilation";
    this.setState(FirstCompilationState);
    if(userRes.isUserIdentified){
      const targetElement=document.getElementById("generatedFormPlaceholder");
      if(targetElement){
        const config={ attributes: true, childList: true, subtree: true };
        const mutationCallback=(mutationList:any,observer:any)=>{
          if(document.getElementById("generatedForm")){
            if((document.getElementById("surname") as HTMLInputElement)){
              (document.getElementById("surname") as HTMLInputElement).value=userRes.user.surname;
              (document.getElementById("surname") as HTMLInputElement).dispatchEvent(new InputEvent("input"));
            }
            if((document.getElementById("name") as HTMLInputElement)){
              (document.getElementById("name") as HTMLInputElement).value=userRes.user.name;
              (document.getElementById("name") as HTMLInputElement).dispatchEvent(new InputEvent("input"));
            }
            if((document.getElementById("dateOfBirth") as HTMLInputElement)) {
              (document.getElementById("dateOfBirth") as HTMLInputElement).value = userRes.user.birthDate;
              (document.getElementById("dateOfBirth") as HTMLInputElement).dispatchEvent(new InputEvent("input"));
            }
            if((document.getElementById("fiscalCode") as HTMLInputElement)){
              (document.getElementById("fiscalCode") as HTMLInputElement).value=userRes.user.fiscalCode;
              (document.getElementById("fiscalCode") as HTMLInputElement).dispatchEvent(new InputEvent("input"));
            }
          }
        }
        const observer=new MutationObserver(mutationCallback);
        observer.observe(targetElement,config);
      }
    }
    let garbageInfo="";
    [this.language,this.company,this.template,garbageInfo]=this.templateToCompile.split('/');
    await wLazyForm.getForm(this.language,this.company,this.template,false,true,true,true,false,'',false,'wLazyDefaultAccess','asdghjkl').then(async (res) => {
      this.compiledTemplate=res.compiledPdf;
      this.compiledForm=res.JSONMap;
      console.log(this.compiledForm);
      FirstCompilationState.onScreen = "previewPdf";
      this.setState(FirstCompilationState);
    }).catch((err)=>{console.log(err)});
  }

  private async saveCompilation() {
        await ApiService.callToServer("documenti","saveUserCompilation","v1",{
          CompanyName:FirstCompilation.companyName,
          compiledData:this.compiledForm,
          TemplateID:this.templateToCompile,
          TaxCode:this.state.taxCode,
          State:"COMPILED"
        }).catch((e)=>{console.error(e)});
        FirstCompilationState.onScreen = "endingCompilation";
        this.setState(FirstCompilationState);
  }

  private showOverlay() {
    FirstCompilationState.onScreen="waitingForServer";
    this.setState(FirstCompilationState);
  }

  private checkCF() {
    let CFInputElement=document.getElementById("codiceFiscale");
    if(CFInputElement){
      let CF=(CFInputElement as HTMLInputElement).value;
      if(CF.length===16){
        if(CodiceFiscale.check(CF)){
          FirstCompilationState.taxCode=CF;
          FirstCompilationState.disabledSendButton=false;
        }
      }
      else{
        if(!this.state.disabledSendButton){
          FirstCompilationState.disabledSendButton=true;
        }
      }
      this.setState(FirstCompilationState);
    }
  }

  private cancelCompilation() {
    const formPlaceholder=document.getElementById("generatedFormPlaceholder")
    if(formPlaceholder){
      formPlaceholder.innerHTML="";
    }
    FirstCompilationState.onScreen="cfInsertion";
    this.setState(FirstCompilationState);
  }

  private async recompileForm(){
    FirstCompilationState.onScreen="templateCompilation";
    this.setState(FirstCompilationState);
    await new Promise(resolve => setTimeout(resolve, 200));
    const targetElement=document.getElementById("generatedFormPlaceholder");
        if(targetElement){
            const config={ attributes: true, childList: true, subtree: true };
            const mutationCallback=(mutationList:any,observer:any)=>{
                if(document.getElementById("generatedForm")){
                  this.compiledForm.forEach((compiledData:{id:string,value?:string,checked?:string}) => {
                    if(document.getElementById(compiledData.id) as HTMLInputElement || document.getElementById(`${compiledData.id}Yes`) as HTMLInputElement){
                      if(compiledData.value){
                        (document.getElementById(compiledData.id) as HTMLInputElement).value=compiledData.value;
                        (document.getElementById(compiledData.id) as HTMLInputElement).dispatchEvent(new InputEvent("input"));
                      }
                      if(compiledData.checked) {
                        if(document.getElementById(compiledData.id) as HTMLInputElement){
                          (document.getElementById(compiledData.id) as HTMLInputElement).checked=compiledData.checked==="true";
                          (document.getElementById(compiledData.id) as HTMLInputElement).dispatchEvent(new InputEvent("input"));
                        }
                        else{
                          compiledData.checked==="true"?(document.getElementById(`${compiledData.id}Yes`) as HTMLInputElement).checked=true:(document.getElementById(`${compiledData.id}No`) as HTMLInputElement).checked=true;
                          compiledData.checked==="true"?(document.getElementById(compiledData.id+'Yes') as HTMLInputElement).dispatchEvent(new InputEvent("input")):(document.getElementById(compiledData.id+'No') as HTMLInputElement).dispatchEvent(new InputEvent("input"));
      
                        }
                      }
                    }
                  })
              }
            }
            const observer=new MutationObserver(mutationCallback);
            observer.observe(targetElement,config);
        }
    let garbageInfo="";
    await wLazyForm.getForm(this.language,this.company,this.template,false,true,true,true,false,'',false,'wLazyDefaultAccess','asdghjkl').then(async (res) => {
      this.compiledTemplate=res.compiledPdf;
      this.compiledForm=res.JSONMap;
      FirstCompilationState.onScreen = "previewPdf";
      this.setState(FirstCompilationState);
    }).catch((err)=>{console.log(err)});
  }
}

