import React from 'react';
import INotEnabledProps from "./INotEnabledProps";
import ISessionDataModel from '../ISessionDataModel';
import INotEnabledState from './INotEnabledState';
import ResultState from "./NotEnabledState.json";
import "./NotEnabled.css";
import App from "../../App";


export default class NotEnabled extends React.Component<INotEnabledProps, ISessionDataModel & INotEnabledState> {
  constructor(props: any) {
    super(props);
    if (this.props.data) {
      this.state = {
        ...this.props.data,
        ...ResultState
      }
    }
  }

  componentDidMount(): void {
    document.title="Non autorizzato-wDesk"
  }

  componentWillUnmount(): void {
    document.title="wDesk"
  }
  render() {

    console.log("Entrato nel render");
    return (
        <div className="scrollable-content mt-5 pt-3">
          <div>
            <h1>Spiacenti! Questa funzionalità non è abilitata per questo tenant.</h1>
          </div>
        </div>
    )
  }

}

