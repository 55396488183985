export function enwscc(p_address, p_pluginName) {
    this.__address = p_address;
    this.__pluginName = p_pluginName;
}

enwscc.prototype = {

    /**
     * The version of ENWSCC.
     */
    version: 20200320,

    /**
     * Determines if the websocket is connecting.
     */
    __isConnecting: false,

    /**
     * The guid that identifies the current socket connection.
     */
    __socketId: null,

    /**
     * The WebSocket object used to connect to ENMonitor.
     */
    __socket: null,

    /**
     * The websocket address.
     */
    __address: null,

    /**
     * The name of the process plugin.
     */
    __pluginName: null,

    /**
     * Stores the callback to be executed when a DeviceInfo message is received.
     */
    __onDeviceInfo: null,

    /**
     * Stores the callback to be executed when an event is received.
     */
    __onEvent: null,

    /**
     * Stores the callback executed when the socket connection is estabilished.
     */
    __onConnected: null,

    /**
     * Stores the callback executed when the socket connection is closed.
     */
    __onClose: null,

    /**
     * Determines if the onClose callback should be avoided.
     */
    __avoidOnCloseEvent: false,

    /**
     * Binds the command ids to the callback to be executed.
     */
    __commands: {},

    /**
     * Memorize the callback for the given command id.
     * @param {any} p_commandId The identifier of the command.
     * @param {any} p_callback The callback to be executed for the given p_commandId.
     */
    __commandsAdd: function (p_commandId, p_callback) {

        this.__commands[p_commandId] = p_callback;

    },

    /**
     * Removes the callback for the command id.
     * @param {string} p_commandId The identifier of the command.
     */
    __commandsRemove: function (p_commandId) {

        delete this.__commands[p_commandId];

    },

    /**
     * Executes the callback function for the given command id.
     * @param {string} p_commandId The identifier of the command.
     * @param {any} p_pcdo The pcdo object to provide to the callback.
     */
    __commandExecute: function (p_commandId, p_pcdo) {

        if (this.__commands[p_commandId] !== undefined) {

            console.log('[enwscc] executing callback for command id \'' + p_commandId + '\'.');
            this.__commands[p_commandId](p_pcdo);

        } else {

            console.warn('[enwscc] callback for command id \'' + p_commandId + '\' not found!');
        }

    },

    /**
     * Executes the onEvent callback, if set.
     * @param {any} p_pcdo The pcdo to provide to the callback.
     */
    __invoke_onEvent: function (p_pcdo) {

        if (this.__onEvent !== null &&
            this.__onEvent !== undefined) {

            this.__onEvent(p_pcdo);

        }

    },


    /**
     * Executes the onDeviceInfo callback, if set.
     * @param {any} p_pcdo The pcdo object to provide to the onDeviceInfo callback function.
     */
    __invoke_onDeviceInfo: function (p_pcdo) {

        if (this.__onDeviceInfo !== null &&
            this.__onDeviceInfo !== undefined) {

            var deviceData = JSON.parse(p_pcdo.Data);

            this.__onDeviceInfo({
                Event: p_pcdo.Command,
                DeviceName: deviceData.Name
            });

        }

    },

    /**
     * Executes the onClose callback.
     * @param {number} p_code The code to be provided to the onClose callback function.
     */
    __invoke_onClose: function (p_code) {

        if (!this.__avoidOnCloseEvent && this.__onClose !== null && this.__onClose !== undefined) {

            this.__onClose(p_code);

        }

    },

    /**
     * Executes the onConnected callback.
     * @param {boolean} p_connected A value indicating if the connection was successfull.
     */
    __invoke_onConnected: function (p_connected) {

        if (this.__onConnected !== null && this.__onConnected !== undefined) {

            this.__onConnected(p_connected);

        }

    },

    /**
     * Sends a message trough the socket connection.
     * @param {string} p_message The message to be sent.
     */
    __socket_send: function (p_message) {

        console.log('[enwscc] sending message...');

        this.__socket.send(p_message);

    },

    /**
     * This callback is executed when the socket connection is estabilished.
     * @param {any} p_payload The payload of the callback function.
     */
    __socket_onOpen: function (p_payload) {

        console.log('[enwscc] socket->onOpen(): connection estabilished.');

        this.isMonitorAvailable = true;

        this.__checkProcessPlugin();

    },

    /**
     * This callback is executed when the socket connection is closed.
     * @param {any} p_payload The payload of the callback function.
     */
    __socket_onClose: function (p_payload) {

        // payload.code

        console.log('[enwscc] socket->onClose() => code: ' + p_payload.code + ', reason: ' + p_payload.reason + ', wasClean: ' + p_payload.wasClean);

        // https://tools.ietf.org/html/rfc6455#section-11.7

        // 1000 is a normal close, 1001 is client disconnecting.

        this.__invoke_onClose(p_payload.code);

        this.__reset();
    },

    /**
     * This callback is executed when the socket connection gets an error.
     * @param {any} p_payload The payload of the callback function.
     */
    __socket_onError: function (p_payload) {

        // Note: on this callback, the error reason is hidden by design.

        // TODO: what to do here?

        console.log('[enwscc] socket->onError() => __isConnecting? ' + this.__isConnecting);

        if (this.__isConnecting) {

            this.__avoidOnCloseEvent = true;

            this.__invoke_onConnected(false);

        }

    },

    /**
     * This callback is executed when the socket receives a new message.
     * @param {any} p_payload The payload of the callback function.
     */
    __socket_onMessage: function (p_payload) {

        /*
        {
            "ProcessPluginName": null,
            "Target": "ENDemo_ProcessPlugin",
            "Response": "ReturnData",
            "Command": "Custom",
            "Data": "\"Target 'ENDemo_ProcessPlugin' not found.\"",
            "DataType": "Json",
            "SocketID": "c71ecde6-8741-40f7-b2d8-7b714168dfc6",
            "CommandID": "9f791e80-f21a-4fdf-a54f-8de1492d19c9",
            "IsError": true
        }

        {
            "ProcessPluginName": "ENDeviceSegregation_ProcessPlugin",
            "Target": "ENMessenger_CommunicationPlugin",
            "Response": "ReturnData",
            "Command": "OnWindowMoved",
            "Data": "{\r\n  \"Event\": \"MovedOut\",\r\n  \"Handle\": 201134,\r\n  \"Title\": \"localhost e un'altra pagina ‎- Microsoft Edge\",\r\n  \"ClassName\": \"ApplicationFrameWindow\"\r\n}",
            "DataType": "Json",
            "SocketID": "8d680195-0283-4fff-90f7-4677e00ce2b6",
            "CommandID": "@EVENT@",
            "IsError": false
        }
         */

        // TODO: remove data logging for non-debug builds.
        //console.log('[enwscc] socket->onMessage() => received a new message: \'' + p_payload.data + '\'');

        var pcdo = JSON.parse(p_payload.data);

        //console.log('[enwscc] pcdo object:');
        //console.log(pcdo);

        if (pcdo.Response !== undefined &&
            pcdo.Response !== null &&
            pcdo.Response === 'DeviceInfo') {

            // Device information on pcdo.Data

            this.__invoke_onDeviceInfo(pcdo);

        } else if (pcdo.CommandID === '@EVENT@') {

            if (pcdo.SocketID === this.__socketId) {

                this.__invoke_onEvent(pcdo);

            }

        } else {

            this.__commandExecute(pcdo.CommandID, pcdo);

            this.__commandsRemove(pcdo.CommandID);

        }
    },

    /**
     * Generates the pcdo object to be sent.
     * @param {string} p_processPlugin The target process plugin.
     * @param {string} p_command The command to be executed.
     * @param {string[]} p_payload The payload for the command.
     * @return {any} The generated pcdo object.
     */
    __getPcdoObjectExt: function (p_processPlugin, p_command, p_payload) {

        // Generates the pcdo object to be sent.

        console.log('[enwscc] building pcdo object, target: \'' + p_processPlugin + '\', command: \'' + p_command + '\'.');

        // TODO: C# sets StringArray if p_payload is string[], it's ok to check only if it's an array?
        var dataType = Array.isArray(p_payload) ? 'StringArray' : 'Json';

        console.log('[enwscc] data type is \'' + dataType + '\'.');

        return {
            Target: p_processPlugin,
            Command: p_command,
            DataType: dataType,
            Data: JSON.stringify(p_payload),
            SocketID: this.__socketId,
            CommandID: this.newGuid()
        };

    },

    /**
     * Builds the pcdo object.
     * @param {string} p_command The command to be executed.
     * @param {string[]} p_payload The payload of the command.
     * @returns {any} The generated pcdo object.
     */
    __getPcdoObject: function (p_command, p_payload) {

        return this.__getPcdoObjectExt(this.__pluginName, p_command, p_payload);

    },

    /**
     * Resets the object.
     */
    __reset: function () {

        this.isMonitorAvailable = false;
        this.isPluginAvailable = false;
        this.__isConnecting = false;
        this.__avoidOnCloseEvent = false;
        this.__onConnected = null;
        if (this.__socket !== undefined && this.__socket !== null) {
            this.__socket.onerror = null;
            this.__socket.onopen = null;
            this.__socket.onclose = null;
            this.__socket.onmessage = null;
            this.__socket = null;
        }

    },

    /**
     * Builds the envelope object.
     * @param {string} p_messageType The type of the message.
     * @param {any} p_payload The payload for the command execution.
     * @param {boolean} p_wait Determines if the command response should be waited.
     * @return {string} The envelope object to be sent, encoded in base64.
     */
    __buildEnvelope: function (p_messageType, p_payload, p_wait) {

        var serialized = JSON.stringify(p_payload);

        return this.toBase64(JSON.stringify({

            MessageType: p_messageType,

            Payload: serialized,

            Wait: p_wait

        }));

    },

    /**
     * Sends a command.
     * @param {any} p_command The commando to be sent.
     * @param {any} p_payload The payload for the command.
     * @param {any} p_onComplete The callback to be executed when the command completes.
     * @param {any} p_wait Determines if the command execution should be waited.
     */
    __sendCommand: function (p_command, p_payload, p_onComplete, p_wait) {

        var pcdo = this.__getPcdoObject(p_command, p_payload);

        this.__commandsAdd(pcdo.CommandID, p_onComplete);

        console.log('[enwscc] Sending command with SID: \'' + pcdo.SocketID + '\' and CID: \'' + pcdo.CommandID + '\'.');

        this.__socket_send(this.__buildEnvelope('PCDO', pcdo, p_wait));

    },

    /**
     * Runs the process plugin check process.
     */
    __checkProcessPlugin: function () {

        // See SdkProcessPluginBase.cs -> IsProcessPluginLoaded.

        var objSend = {
            IsError: false,
            Error: {
                ID: -1,
                Description: null
            },
            Data: {
                Values: {
                    Name: this.__pluginName
                }
            }
        };

        var pcdo = this.__getPcdoObjectExt('@Core', 'Custom', ['IsProcessPluginLoaded', JSON.stringify(objSend)]);

        this.__commandsAdd(pcdo.CommandID, function (p_pcdo) {

            var res = JSON.parse(p_pcdo.Data);

            this.isPluginAvailable = res.Data.Values.IsLoaded;

            this.__invoke_onConnected(this.isPluginAvailable);

            this.__isConnecting = false;

        }.bind(this));

        console.log('[enwscc] Checking process plugin availability with SID: \'' + pcdo.SocketID + '\' and CID: \'' + pcdo.CommandID + '\'.');

        this.__socket_send(this.__buildEnvelope('PCDO', pcdo, true));

    },

    /**
     * Determines if ENMonitorService is available.
     */
    isMonitorAvailable: false,

    /**
     * Determines if the process plugin is available.
     */
    isPluginAvailable: false,

    /**
     * Opens the connection to ENMonitorService.
     * @param {any} p_onConnected The callback to be executed when the connection to ENMonitorService is ready, or not. The callback accepts a boolean parameter that determines if the connection was estabilished or not.
     * @param {any} p_onClose The callback to be executed when the websockets connection is closed.
     */
    open: function (p_onConnected, p_onClose) {

        try {

            console.info('ENWSCC v. ' + this.version + ', (c) 2019 Euronovate SA.');

            if (this.__socket !== null && this.__socket !== undefined) {

                throw 'Connection to \'' + this.__address + '\' already opened.';

            }

            var address = this.__address;

            if (address === undefined || address === null || address === '') {

                address = 'ws://127.0.0.1:8001';

            }

            console.log('[enwscc] estabilishing connection to \'' + this.__address + '\' for plugin \'' + this.__pluginName + '\'.');

            this.__socketId = this.newGuid();

            console.log('[enwscc] socket id is: \'' + this.__socketId + '\'.');

            this.__isConnecting = true;
            this.__avoidOnCloseEvent = false;

            this.__onConnected = p_onConnected;
            this.__onClose = p_onClose;

            this.__socket = new WebSocket(this.__address);

            this.__socket.onerror = this.__socket_onError.bind(this);
            this.__socket.onopen = this.__socket_onOpen.bind(this);
            this.__socket.onclose = this.__socket_onClose.bind(this);
            this.__socket.onmessage = this.__socket_onMessage.bind(this);

        } catch (e) {

            // Creating a websocket connection throws an immediate exception in some cases (eg. connecting to a non-secure address from a secure environment).
            // If the websocket server isn't running, the onError and onClose callbacks are executed.

            console.log('[enwscc] failed initializing socket connection: \'' + e.message + '\'.');

            this.__invoke_onConnected(false);

            this.__reset();

        }
    },

    /**
     * Closes the connection to ENMonitor.
     */
    close: function () {

        if (this.__socket !== null && this.__socket !== undefined) {

            this.__socket.close(1000, 'CLOSE_NORMAL');
        }

        this.__reset();

    },

    /**
     * Sends a log message to be stored on ENMonitorService logs.
     * @param {any} p_level The log level. Accepted values are "DEBUG", "VERBOSE", "INFO", "WARNING", "ERROR".
     * @param {any} p_message The log message to be saved.
     */
    log: function (p_level, p_message) {

        this.__socket_send(this.__buildEnvelope('LOG', new {

            Level: p_level,

            Message: p_message

        }, false));

    },

    /**
     * Sends a command to the configured process plugin.
     * @param {any} p_command The command to be executed.
     * @param {any} p_payload A string array containing the payload for the command.
     * @param {any} p_onComplete The callback to be executed. The callback is executed as soon as the command is dispatched to the process plugin. The function accepts an input parameter, a pcdo object.
     */
    sendCommand: function (p_command, p_payload, p_onComplete) {

        this.__sendCommand(p_command, p_payload, p_onComplete, false);

    },

    /**
     * Sends a command to the configured process plugin, and waits for the command execution to be completed.
     * @param {any} p_command The command to be executed.
     * @param {any} p_payload A string array containing the payload for the command.
     * @param {any} p_onComplete The callback to be executed. The callback is executed only when the execution terminates on the process plugin. The function accepts an input parameter, a pcdo object.
     */
    sendCommandAndWait: function (p_command, p_payload, p_onComplete) {

        this.__sendCommand(p_command, p_payload, p_onComplete, true);

    },

    /**
     * Sets the callback to be executed when a device info event is received.
     * @param {any} p_callback The callback function to be executed when a OnDeviceInfo event is received. The funcion accepts an object as input, with two properties: Event and DeviceName.
     */
    setOnDeviceInfoCallback: function (p_callback) {

        this.__onDeviceInfo = p_callback;

    },

    /**
     * Sets the callback to be executed when an event for the sdk is received.
     * @param {any} p_callback the callback function to be executed when an event is received. The function accepts a pcdo object as input.
     */
    setOnEventCallback: function (p_callback) {

        this.__onEvent = p_callback;

    },

    /**
     * Checks is a variable is a function.
     * @param {any} p_functionToCheck The variable to check.
     * @return {boolean} True if p_functionToCheck is a function.
     */
    isFunction: function (p_functionToCheck) {

        // As seen on https://stackoverflow.com/questions/5999998/check-if-a-variable-is-of-function-type

        return p_functionToCheck && {}.toString.call(p_functionToCheck) === '[object Function]';

    },

    //
    /**
     * Checks if a variable is a string.
     * @param {any} p_string The variable to check.
     * @return {boolean} True if p_string is a string.
     */
    isString: function (p_string) {

        // As seen on https://stackoverflow.com/questions/4059147/check-if-a-variable-is-a-string-in-javascript

        return typeof p_string === 'string' || p_string instanceof String;

    },

    /**
     * Checks if a variable is a boolean.
     * @param {any} p_boolean The variable to check.
     * @return {boolean} True if p_boolean is a boolean.
     */
    isBoolean: function (p_boolean) {

        return typeof p_boolean === 'boolean' || p_boolean instanceof Boolean;

    },

    /**
     * Determines if a variable is an object.
     * @param {any} p_object The variable to check.
     * @return {boolean} True if p_object is an object.
     */
    isObject: function (p_object) {

        return typeof p_object === 'object' || p_object instanceof Object;

    },

    /**
     * Determines if a variable is a number.
     * @param {any} p_number The variable to check.
     * @return {boolean} True if p_number is an object.
     */
    isNumber: function (p_number) {

        return typeof p_number === 'number' || p_number instanceof Number;

    },

    /**
     * Checks is a PCDO object is in error state.
     * @param {any} p_pcdo The pcdo object to check.
     * @return {boolean} True if p_pcdo is in an error state.
     */
    isJsonError: function (p_pcdo) {

        if (p_pcdo === null || p_pcdo === undefined) {
            return true;
        }

        return p_pcdo.IsError;

    },

    /**
     * CHecks if a guid is valid.
     * @param {string} p_guid The guid to be checked.
     * @return {boolean} True if p_guid is a valid guid.
     */
    isValidGuid: function (p_guid) {

        // As seen on https://stackoverflow.com/questions/7905929/how-to-test-valid-uuid-guid

        //return /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(p_guid);

        return /^[0-9a-f]{8}-?[0-9a-f]{4}-?[1-5][0-9a-f]{3}-?[89ab][0-9a-f]{3}-?[0-9a-f]{12}$/i.test(p_guid);

    },

    /**
     * Generates a new guid string.
     * @returns {string} The newly generate guid.
     */
    newGuid: function () {

        // As seen on: https://stackoverflow.com/questions/105034/create-guid-uuid-in-javascript

        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    },

    /**
     * Encodes a string to base64.
     * @param {string} p_input The input string to be encoded.
     * @return {string} The encoded string.
     */
    toBase64: function (p_input) {

        // TODO: probably this has to be reviewed, as it takes as input a string where each character represents an 8-bit byte.
        // It may break with UTF8+ strings.
        // https://stackoverflow.com/questions/246801/how-can-you-encode-a-string-to-base64-in-javascript

        return btoa(p_input);
    },

    /**
     * Decodes a base64 string.
     * @param {string} p_input The string to be decoded.
     * @return {string} The decoded string.
     */
    fromBase64: function (p_input) {

        return atob(p_input);

    },
};

ENLazyFormClient.prototype = new enwscc();

/**
 * Creates a new ENSoftClient object.
 *
 * @param {any} p_address The websocket address to connect to.
 */
export default function ENLazyFormClient(p_address) {
    // 1.0: first version.
    this.ClientVersion = '1.0';


    // ENWSCC initialization
    enwscc.call(this, p_address, 'ENLazyForm_ProcessPlugin');

    //#region EVENTS

    // /**
    //  * Invoked when a signature field is created on the document.
    //  */
    // this.OnCreateSignResponse = null;
    // this.__invokeOnCreateSignResponse = function (p_data) {
    //     if (this.OnCreateSignResponse) {
    //         // p_data is a serialized AddedSignEventArgs.
    //         this.OnCreateSignResponse(JSON.parse(p_data));
    //     }
    // };

    // this.setOnEventCallback(function (p_pcdo) {

    //     if (p_pcdo.Response === 'ReturnData') {

    //         switch (p_pcdo.Command) {

    //             case 'VIEWER_BUTTON_CLICK_CONFIRM':
    //                 this.__invokeOnUserConfirm(p_pcdo.Data);
    //                 break;
    //         }
    //     }
    // });

    //#endregion EVENTS

    //#region UTILITIES
    this.__executeCloseCommand = function (p_wait, p_subCommand, p_parameter1, p_parameter2) {
        var onCompleteCallback = undefined;
        try {
            var commandParameters;

            if (this.isFunction(p_parameter1)) {
                onCompleteCallback = p_parameter1;
                commandParameters = [p_subCommand, ''];
            } else if (this.isFunction(p_parameter2)) {
                commandParameters = [p_subCommand, p_parameter1];
                onCompleteCallback = p_parameter2;
            } else if (p_parameter1 !== undefined && p_parameter1 !== null) {
                commandParameters = [p_subCommand, p_parameter1];
            } else {
                commandParameters = [p_subCommand, ''];
            }

            // On the c# sdk there is a difference between Abort and Confirm...

            if (p_wait) {
                this.sendCommandAndWait('Close', commandParameters, (pcdo) => {
                    if (onCompleteCallback !== undefined) {
                        onCompleteCallback(this.isJsonError(pcdo));
                    }
                });
            } else {
                this.sendCommand('Close', commandParameters, (pcdo) => {
                    if (onCompleteCallback !== undefined) {
                        onCompleteCallback(this.isJsonError(pcdo));
                    }
                });
            }
        } catch(e) {
            console.error(e.message);
            if (onCompleteCallback) {
                onCompleteCallback(false);
            }
        }
    };

    this.__executeBaseCommandExt = function (p_wait, p_command, p_parameters, p_callback) {
        var callback = undefined;
        if (p_callback !== undefined && p_callback !== null) {
            if (this.isFunction(p_callback)) {
                callback = p_callback;
            } else {
                console.error('Invalid callback function.');
            }
        }

        if (p_wait) {
            this.sendCommandAndWait(p_command, p_parameters, (pcdo) => {
                var error = this.isJsonError(pcdo);
                if (error) {
                    console.error('Command execution reported an error.');
                }
                if (callback !== undefined) {
                    callback(!error);
                }
            });
        } else {
            this.sendCommand(p_command, p_parameters, (pcdo) => {
                var error = this.isJsonError(pcdo);
                if (error) {
                    console.error('Command execution reported an error.');
                }
                if (callback !== undefined) {
                    callback(!error);
                }
            });
        }
    };

    this.__executeBaseCommand = function (p_command, p_parameters, p_callback) {
        this.__executeBaseCommandExt(false, p_command, p_parameters, p_callback);
    };

    //#endregion UTILITIES

    //#region METHODS

    this.Show = function (p_url, p_callback) {
        try {
            this.sendCommandAndWait('Show', [  this.toBase64(p_url) ], (pcdo) => {
                var error = this.isJsonError(pcdo);
                if (error) {
                    console.error('Command execution reported an error.');
                }
                if (p_callback) {
                    if (error) {
                        p_callback(JSON.parse(this.fromBase64(JSON.parse(pcdo.Data))));
                    } else {
                        p_callback(null);
                    }
                }
            });
        } catch (e) {
            console.error(e.message);
            if (p_callback) {
                p_callback(null);
            }
        }
    }

    this.Hide = function (p_callback) {
        try {
            this.sendCommandAndWait('Hide', [ ], (pcdo) => {
                var error = this.isJsonError(pcdo);
                if (error) {
                    console.error('Command execution reported an error.');
                }
                if (p_callback) {
                    if (error) {
                        p_callback(JSON.parse(this.fromBase64(JSON.parse(pcdo.Data))));
                    } else {
                        p_callback(null);
                    }
                }
            });
        } catch (e) {
            console.error(e.message);
            if (p_callback) {
                p_callback(null);
            }
        }
    }

    this.Minimize = function (p_callback) {
        try {
            this.sendCommandAndWait('Custom', [ 'Minimize' ], (pcdo) => {
                var error = this.isJsonError(pcdo);
                if (error) {
                    console.error('Command execution reported an error.');
                }
                if (p_callback) {
                    if (error) {
                        p_callback(JSON.parse(this.fromBase64(JSON.parse(pcdo.Data))));
                    } else {
                        p_callback(null);
                    }
                }
            });
        } catch (e) {
            console.error(e.message);
            if (p_callback) {
                p_callback(null);
            }
        }
    }

    this.Maximize = function (p_callback) {
        try {
            this.sendCommandAndWait('Custom', [ 'Maximize' ], (pcdo) => {
                var error = this.isJsonError(pcdo);
                if (error) {
                    console.error('Command execution reported an error.');
                }
                if (p_callback) {
                    if (error) {
                        p_callback(JSON.parse(this.fromBase64(JSON.parse(pcdo.Data))));
                    } else {
                        p_callback(null);
                    }
                }
            });
        } catch (e) {
            console.error(e.message);
            if (p_callback) {
                p_callback(null);
            }
        }
    }

    this.IsReady = function (p_callback) {
        try {
            this.sendCommandAndWait('Custom', [ 'IsReady' ], (pcdo) => {
                var error = this.isJsonError(pcdo);
                if (error) {
                    console.error('Command execution reported an error.');
                }
                if (p_callback) {
                    if (error) {
                        p_callback(JSON.parse(this.fromBase64(JSON.parse(pcdo.Data))));
                    } else {
                        p_callback(null);
                    }
                }
            });
        } catch (e) {
            console.error(e.message);
            if (p_callback) {
                p_callback(null);
            }
        }
    }

    this.ShowDevTool = function (p_callback) {
        try {
            this.sendCommandAndWait('Custom', [ 'ShowDevTool' ], (pcdo) => {
                var error = this.isJsonError(pcdo);
                if (error) {
                    console.error('Command execution reported an error.');
                }
                if (p_callback) {
                    if (error) {
                        p_callback(JSON.parse(this.fromBase64(JSON.parse(pcdo.Data))));
                    } else {
                        p_callback(null);
                    }
                }
            });
        } catch (e) {
            console.error(e.message);
            if (p_callback) {
                p_callback(null);
            }
        }
    }

    //#endregion METHODS

}