import React from 'react';
import ITabletCompilationProps from "./ITabletCompilationProps";
import ITabletCompilationState from './ITabletCompilationState';
import TabletCompilationState from "./TabletCompilationState.json";
import "./TabletCompilation.css";
import Header from "../Header/Header";
import wLazyForm from "@en/wlazyform";
import {ApiService} from "../../libs/ApiService";
import App from "../../App";
import NotEnabled from "../NotEnabled/NotEnabled";

export default class TabletCompilation extends React.Component<ITabletCompilationProps, ITabletCompilationState> {

  private templateToCompile:string="";
  private language:string="";
  private company:string="";
  private template:string="";
  private elementIndex:number=-1;
  private compiledForm:any=null;
  private tabletCompilation: boolean = false;
  private static companyName: string = "";

  constructor(props: any) {
    super(props);
    this.state = TabletCompilationState;
  }

  async componentDidMount(): Promise<void> {
    document.title = "Compilazione da tablet-wDesk";
    this.tabletCompilation = (await App.getConfig()).Features.TabletCompilation;
    if (this.tabletCompilation) {
      try {
        [this.templateToCompile, TabletCompilationState.taxCode] = atob(window.location.search.split("template=")[1]).split('>');
        this.setState(TabletCompilationState);
      } catch (e) {
        console.error(e);
      }
      const dynamicServerURL: string = "https://" + window.location.hostname + "/backend"
      ApiService.setServerURL(process.env.REACT_APP_BACKEND_ENDPOINT ? process.env.REACT_APP_BACKEND_ENDPOINT : dynamicServerURL);
      let tenant=(await App.getConfig()).MyExpress.Context.Tenant
      TabletCompilation.companyName= tenant !== "@DEFAULT"?tenant:"Euronovate";
      this.showOverlay();
      const userRes: {
        isUserIdentified: boolean,
        user: {
          name: string,
          surname: string,
          fiscalCode: string,
          birthDate: any,
          email: string,
          phone: any,
          birthCity: string,
          birthProvince: string,
          birthCountry: string,
          sex: string,
          AESAcceptDate: any
        },
        hasUserAlreadySignedFEA: boolean
      } = await ApiService.callToServer("anagrafica", "getUserFromCF", "v1", {
        CF: this.state.taxCode,
        CompanyName: TabletCompilation.companyName
      });
      TabletCompilationState.onScreen = "templateCompilation";
      this.setState(TabletCompilationState);
      if (userRes.isUserIdentified) {
          
        const targetElement=document.getElementById("generatedFormPlaceholder");
        if(targetElement){
            const config={ attributes: true, childList: true, subtree: true };
            const mutationCallback=()=>{
              if (document.getElementById("generatedForm")) {
                if ((document.getElementById("surname") as HTMLInputElement)) {
                  (document.getElementById("surname") as HTMLInputElement).value = userRes.user.surname;
                  (document.getElementById("surname") as HTMLInputElement).dispatchEvent(new InputEvent("input"));
                }
                if ((document.getElementById("name") as HTMLInputElement)) {
                  (document.getElementById("name") as HTMLInputElement).value = userRes.user.name;
                  (document.getElementById("name") as HTMLInputElement).dispatchEvent(new InputEvent("input"));
                }
                if ((document.getElementById("dateOfBirth") as HTMLInputElement)) {
                  (document.getElementById("dateOfBirth") as HTMLInputElement).value = userRes.user.birthDate;
                  (document.getElementById("dateOfBirth") as HTMLInputElement).dispatchEvent(new InputEvent("input"));
                }
                if ((document.getElementById("fiscalCode") as HTMLInputElement)) {
                  (document.getElementById("fiscalCode") as HTMLInputElement).value = userRes.user.fiscalCode;
                  (document.getElementById("fiscalCode") as HTMLInputElement).dispatchEvent(new InputEvent("input"));
                }
                if ((document.getElementById("email") as HTMLInputElement)) {
                  (document.getElementById("email") as HTMLInputElement).value = userRes.user.email;
                  (document.getElementById("email") as HTMLInputElement).dispatchEvent(new InputEvent("input"));
                }
                if ((document.getElementById("phone") as HTMLInputElement)) {
                  (document.getElementById("phone") as HTMLInputElement).value = userRes.user.phone;
                  (document.getElementById("phone") as HTMLInputElement).dispatchEvent(new InputEvent("input"));
                }
                let inputsForm=(document.getElementById('generatedForm') as HTMLFormElement).getElementsByTagName('input');
                for(let i=0;i<inputsForm.length;i++){
                  inputsForm[i].addEventListener('click',()=>{
                    if(inputsForm[i].type !== "radio" && inputsForm[i].type !== "checkbox" && inputsForm[i].type !== "submit") {
                      this.elementIndex=i;
                    }
                  });
                }
              }
            }
            const observer=new MutationObserver(mutationCallback);
            observer.observe(targetElement,config);
            setTimeout(()=>{
              observer.disconnect();
            },500);
        }
      }
      this.language = this.templateToCompile.split('/')[0];
      this.company = this.templateToCompile.split('/')[1];
      this.template = this.templateToCompile.split('/')[2];
      await wLazyForm.getForm(this.language, this.company, this.template, false, true, true, true,true,'', false,'wLazyDefaultAccess', 'asdghjkl').then(async (res) => {
        this.compiledForm = res.JSONMap;
        this.saveCompilation().then();
      }).catch((err) => {
        console.log(err)
      });
    }
  }

  componentWillUnmount(): void {
    document.title="wDesk"
  }
  render() {

    console.log("Entrato nel render");
    return (
        <div>

    {this.tabletCompilation && (
        <div>
          <Header logout={() => {
          }}></Header>
          <div className="scrollable-content mt-5 pt-3">
            {this.state.onScreen === "endingCompilation" && (
                <div>
                  <div>
                    <h3>La compilazione è stata completata con successo.</h3>
                  </div>
                </div>
            )}
            {this.state.onScreen === "templateCompilation" && (
                <div>
                  <div className="fixed-bottom footer-content">
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col text-start">
                          <button className="btn btn-secondary" id="prevElementBtn" onClick={() => {
                            let allInputs=document.getElementsByTagName('input');
                            if(this.elementIndex<0) {
                              if(allInputs[0].type !== "radio" && allInputs[0].type !== "checkbox" && allInputs[0].type !== "submit") {
                                allInputs[0].focus();
                                allInputs[0].click();
                                this.elementIndex=0;
                              }
                            }
                            else if(this.elementIndex===0) {
                              if(allInputs[allInputs.length-1].type !== "radio" && allInputs[allInputs.length-1].type !== "checkbox" && allInputs[allInputs.length-1].type !== "submit") {
                                allInputs[allInputs.length-1].focus();
                                allInputs[allInputs.length-1].click();
                                this.elementIndex=allInputs.length-1;
                              }
                            }
                            else {
                              let found:boolean=false;
                              for (let i = 1; i < allInputs.length && !found; i++) {
                                for(let j=i-1;j<allInputs.length && !found;j++) {
                                  if (allInputs[this.elementIndex] === allInputs[i]) {
                                    //abbiamo trovato l'elemento attualmente con focus
                                    if(allInputs[j].type !== "radio" && allInputs[j].type !== "checkbox" && allInputs[j].type !== "submit") {
                                      allInputs[j].focus();
                                      allInputs[j].click();
                                      this.elementIndex = j;
                                      found=true;
                                    }
                                  }
                                }
                              }
                            }
                          }}>Precedente
                          </button>
                          <button className="btn btn-secondary" id="nextElementBtn" onClick={() => {
                            let allInputs=document.getElementsByTagName('input');
                            if(this.elementIndex<0) {
                              if(allInputs[0].type !== "radio" && allInputs[0].type !== "checkbox" && allInputs[0].type !== "submit") {
                                allInputs[0].focus();
                                allInputs[0].click();
                                this.elementIndex=0;
                              }
                            }
                            else if(this.elementIndex===allInputs.length-1) {
                              if(allInputs[0].type !== "radio" && allInputs[0].type !== "checkbox" && allInputs[0].type !== "submit") {
                                allInputs[0].focus();
                                allInputs[0].click();
                                this.elementIndex=0;
                              }
                            }
                            else {
                              let found:boolean=false;
                              for (let i = 0; i < allInputs.length  && !found; i++) {
                                for(let j=i+1;j<allInputs.length && !found;j++) {
                                  if (allInputs[this.elementIndex] === allInputs[i]) {
                                    //abbiamo trovato l'elemento attualmente con focus
                                    if(allInputs[j].type !== "radio" && allInputs[j].type !== "checkbox" && allInputs[j].type !== "submit") {
                                      allInputs[j].focus();
                                      allInputs[j].click();
                                      this.elementIndex = j;
                                      found=true;
                                    }
                                  }
                                }
                              }
                            }
                          }}>Successivo
                          </button>
                        </div>
                        <div className="col text-end">
                          <button className="btn btn-secondary" id="compilingFormBtn" onClick={() => {
                            this.cancelCompilation();
                          }}>Annulla
                          </button>
                          <button className="btn btn-primary" id="compilingFormBtn" onClick={() => {
                            this.compiledForm = (document.getElementById("generatedForm") as HTMLFormElement);
                            (document.getElementById("btnSendForm") as HTMLButtonElement).click();
                          }}>Invia
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            )}
            {this.state.onScreen === "waitingForServer" && (
                <div id="overlay">
                  <div id="overlay-content">
                    <div className="loader"></div>
                    <p className="text-light mt-3 fs-4">Caricamento...</p>
                  </div>
                </div>)}
            {(this.state.onScreen === "templateCompilation") && (
                <div id="generatedFormPlaceholder">

                </div>)}
          </div>
        </div>
    )
    }
    {!this.tabletCompilation && (
        <NotEnabled></NotEnabled>
    )}
        </div>
  )
  }
  private async saveCompilation() {
    TabletCompilationState.onScreen = "endingCompilation";
    this.setState(TabletCompilationState);
    const dynamicWebSocketURL: string = `wss://${window.location.hostname}/socket`;
    const wss = new WebSocket(process.env.REACT_APP_WEBSOCKET_ENDPOINT ? process.env.REACT_APP_WEBSOCKET_ENDPOINT : dynamicWebSocketURL);
    wss.onopen = () => {
      wss.send(JSON.stringify({compiledForm: this.compiledForm, templateID: this.templateToCompile}))
    };
    wss.onmessage = () => {
      wss.close()
    };
    wss.onerror=()=>{
      console.error("Websocket connection was not successful!");
    }
  }

  private cancelCompilation() {
    const dynamicWebSocketURL: string = `wss://${window.location.hostname}/socket`;
    const wss = new WebSocket(process.env.REACT_APP_WEBSOCKET_ENDPOINT ? process.env.REACT_APP_WEBSOCKET_ENDPOINT : dynamicWebSocketURL);
    wss.onopen = () => {
      wss.send("ABORT");
    };
    wss.onmessage = () => {
      wss.close()
    };
    wss.onerror=()=>{
      console.error("Websocket connection was not successful!");
    }
  }

  private showOverlay() {
    TabletCompilationState.onScreen = "waitingForServer";
    this.setState(TabletCompilationState);
  }
}

