import ENSoft from './ENSoft';

export default class ENSoftService{
    private static enSoftInstance:ENSoft;

    public static Init(props:any, onDocumentCompletedCallback:Function, onDocumentAbortedCallback:Function, onConnectedCallback:Function, onCLoseCallback:Function){
        this.enSoftInstance=new ENSoft(props,onDocumentCompletedCallback,onDocumentAbortedCallback,onConnectedCallback,onCLoseCallback);
    }

    public static async StartSign(pdfPath:string,isFesSignature:boolean,base64PDF?:string){
        await this.enSoftInstance.startSign(pdfPath,isFesSignature,base64PDF);
    }

    public static async Show(url:string){
        await this.enSoftInstance.showOnBrowser(url);
    }

    public static async GetDocumentStruct(document:string){
        return await this.enSoftInstance.getDocumentStruct(document);
    }

    public static Hide(){
        this.enSoftInstance.hideBrowser();
    }

    public static CloseConnection(){
        this.enSoftInstance.closeConnection();
    }
}