import React from "react";

//import enwscc from './ENWSCC.js'
import ensoft2Sdk from "./ensoft2sdk.js";
import enLazyFormSdk from "./enlazyformsdk";
import ENLazyFormClient from "./enlazyformsdk";

interface ENSoftState {
    status: boolean;
  }

  interface ENSoftProps {
    name?: string;
  }

export default class ENSoft extends React.Component<ENSoftProps, ENSoftState> {

    private status = false;
    private ensoft: ensoft2Sdk;
    private enlazy: ENLazyFormClient;
    //private onDocumentCompletedCallback: Function | null = null;

    constructor(props : any, onDocumentCompletedCallback: Function, onDocumentAbortedCallback:Function, onConnnectedCallback:Function, onCloseCallback:Function) {
        super(props);
        console.log('Istanzio la ensoft2Sdk..');
        this.enlazy = new enLazyFormSdk("ws://127.0.0.1:7000");
        this.ensoft = new ensoft2Sdk('ws://127.0.0.1:7000');


        //this.onDocumentCompletedCallback = onDocumentCompletedCallback;
        this.ensoft.onDocumentCompleted = ((p_payload) => {

            console.log('onDocumentCompleted..');
            if (onDocumentCompletedCallback) {
                console.log('onDocumentCompletedCallback..');
                onDocumentCompletedCallback(p_payload);
            }
        });

        this.ensoft.onDocumentAborted = ((p_payload) => {

            console.log('onDocumentAborted..');
            if (onDocumentAbortedCallback) {
                console.log('onDocumentAbortedCallback..');
                onDocumentAbortedCallback(p_payload);
            }
        });

        this.ensoft.onConnected=((p_payload)=>{
            console.log('onConnected..');
            if (onConnnectedCallback) {
                console.log('onConnectedCallback..');
                console.log(p_payload);
                onConnnectedCallback(p_payload);
            }
        })

        this.ensoft.onClose=((p_payload)=>{
            console.log('onClose..');
            if (onCloseCallback) {
                console.log('onCloseCallback..');
                onCloseCallback(p_payload);
            }
        })

        console.log('Apro la connessione..');
        const p_connected = (connected:boolean) => {
            console.log('connessione conclusa');
            const p_connectedDevice=(res:any)=>{
                onConnnectedCallback(connected && res.Data==="true");
            }
            this.ensoft.isSignatureDeviceConnected(p_connectedDevice);
        };
        const p_code = (code:number) => {
            console.log('code.');
            // Puoi fare qualcos'altro dopo che la firma è stata completata
            onCloseCallback(code);

        };

        this.ensoft.open(p_connected, p_code);
        this.enlazy.open(()=>{},()=>{});
    }

    startSign(pdfPath: string,isFesSignature:boolean,base64PDF?:string){
        if(base64PDF){
            const onCompleteCallback = () => {
                console.log('La firma è stata completata.');
                // Puoi fare qualcos'altro dopo che la firma è stata completata
            };

            // Richiedi il contenuto PDF in modo asincrono
            return new Promise<string>(()=>{
                // Verifica se il risultato è valido prima di chiamare startSign
                if (base64PDF !== null) {
                    console.log('Lancio la StartSign..');
                    if(isFesSignature){
                        //Signature mode must be set exactly like this
                        this.ensoft.setSignatureMode("Fes");
                        console.log('Setting FES signature type');
                    }
                    else{
                        this.ensoft.setSignatureMode("Fea");
                        console.log('Setting FEA signature type');
                    }
                    this.ensoft.setCertificate("C:\\Euronovate\\ENMonitor\\EuronovateSA.pem");
                    this.ensoft.startSign('base64', onCompleteCallback, base64PDF);
                } else {
                    console.error('Il contenuto PDF è nullo.');
                    // Gestisci l'errore o prendi altre misure appropriate
                }
            });
        }
        else{
            const onCompleteCallback = () => {
                console.log('La firma è stata completata.');
                // Puoi fare qualcos'altro dopo che la firma è stata completata
            };

            // Richiedi il contenuto PDF in modo asincrono
            return this.fetchPdfContent(pdfPath).then(pdfContent => {
                console.log('PDF base64..' + pdfContent);
                // Verifica se il risultato è valido prima di chiamare startSign
                if (pdfContent !== null) {
                    console.log('Lancio la StartSign..');
                    this.ensoft.startSign('base64', onCompleteCallback, pdfContent);
                } else {
                    console.error('Il contenuto PDF è nullo.');
                    // Gestisci l'errore o prendi altre misure appropriate
                }
            })
            .catch(error => {
                console.error('Errore durante il recupero del contenuto PDF:', error);
                // Gestisci l'errore o prendi altre misure appropriate
            });
        }


    }

    public closeConnection(){
        this.ensoft.close();
    }

    public async getDocumentStruct(document:string){
        return new Promise((resolve,reject)=>{
            this.ensoft.getDocumentStruct(document,(data)=>{
                resolve(data);
            });
        });

    }

    public showOnBrowser(url:string){
        this.enlazy.Show(url,(date)=>{});
        //this.enlazy.ShowDevTool(()=>{});
    }

    public hideBrowser(){
        this.enlazy.Hide((date)=>{});
    }

    private async fetchPdfContent(pdfPath: string): Promise<string> {
        return new Promise((resolve, reject) => {
            fetch(pdfPath)
                .then(response => response.blob())
                .then(blob => {
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        const base64 = reader.result as string;
                        if (base64.startsWith('data:application/pdf;base64,')) {
                            resolve(base64.substring('data:application/pdf;base64,'.length));
                        } else {
                            reject(new Error('Il file non è un documento PDF'));
                        }
                    };
                    reader.onerror = (error) => reject(error);
                    reader.readAsDataURL(blob);
                })
                .catch(error => reject(error));
        });
    }

}

