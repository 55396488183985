export default class DateUtility{
    public static convertDate(standardDate:string){
        let dateToConvert=new Date(standardDate);
        const year = dateToConvert.getFullYear();
        const month = (dateToConvert.getMonth()+1).toString().padStart(2,"0");
        const day = (dateToConvert.getDate()).toString().padStart(2,"0");
        const hour = (dateToConvert.getHours()).toString().padStart(2,"0");
        const minutes=(dateToConvert.getMinutes()).toString().padStart(2,"0");
        const seconds=(dateToConvert.getSeconds()).toString().padStart(2,"0");
        return `${day}-${month}-${year} ${hour}:${minutes}:${seconds}`;
    }
}