import React from 'react';
import Keycloak from 'keycloak-js';
import Content from './components/Content/Content';
import 'bootstrap/dist/js/bootstrap.bundle'
import { Configuration } from './config/Configuration';
import appConfig from "./config/Configuration.json";
import IKeycloakToken from './libs/IKeycloakToken';
import {ApiService} from "./libs/ApiService";
import ErrorComponent from "./wDocs/ErrorComponent/ErrorComponent";
import FirstCompilation from "./wDocs/FirstCompilationPage/FirstCompilation";
import wLazyForm from "@en/wlazyform";
import DocumentSignPage from "./wDocs/DocumentSignPage/DocumentSignPage";
import {ConfigInterface} from "./interfaces/ConfigInterface";
import TabletCompilation from "./wDocs/TabletCompilation/TabletCompilation";
import NotEnabled from "./wDocs/NotEnabled/NotEnabled";

interface AppState {
  isAuthenticated: boolean;
  isCorrectTenant:boolean;
  keycloakToken?: IKeycloakToken;
  keycloak: Keycloak.KeycloakInstance | null;
  onlyapp:boolean;
  onlyappSelfie:boolean
}
type Feature="TABLET_COMPILATION"|"TEMPLATE_GENERATION";

export default class App extends React.Component<{}, AppState> {
  public static version=require('../package.json').version;
  state: AppState = {
    keycloak: null,
    isAuthenticated: false,
    isCorrectTenant:false,
    keycloakToken: undefined,
    onlyapp:false,
    onlyappSelfie:false
  };

  public static keycloak:Keycloak|null=null;
  public static keycloakUser:string="";
  public static companyName:string="Euronovate";
  public static companyNameToDisplay:string="";
  public static vertical:string="TEST";
  public static environment:string="dev";
  public static features:{
    TemplateGeneration:boolean,
    TabletCompilation: boolean
  };
  public static signatureType:{
    "FES_Sign":boolean,
    "FEA_Sign":boolean
  } | null=null;

  private currentContent = React.createRef<Content>();
  private currentSidebarDiv = React.createRef<HTMLDivElement>();
  // private homeUrl: string = "";
  private clickToShowMenu: boolean = false;
  private static isCompilationGoing:boolean=false;

  constructor(props: any) {
      super(props);
      // this.state.isAuthenticated = false;
      Configuration.Load(appConfig);
      console.log("Costruttore eseguito");
    const dynamicServerURL:string="https://"+window.location.hostname+"/backend"
    const dynamicWLazyFormURL:string="https://"+window.location.hostname+"/wlazyform"
    ApiService.setServerURL(process.env.REACT_APP_BACKEND_ENDPOINT?process.env.REACT_APP_BACKEND_ENDPOINT:dynamicServerURL);
    wLazyForm.Init(process.env.REACT_APP_WLAZYFORM_ENDPOINT?process.env.REACT_APP_WLAZYFORM_ENDPOINT:dynamicWLazyFormURL);
    App.getConfig().then((res)=>
    App.features=res.Features);
  }

  async componentDidMount() {
    document.documentElement.lang="it";
    let config=await App.getConfig();
    App.companyName=config.MyExpress.Context.Tenant!="@DEFAULT"?config.MyExpress.Context.Tenant:"Euronovate";
    App.companyNameToDisplay=config.CompanyName;
    App.vertical=config.Vertical.Name!=""?config.Vertical.Name:"TEST";
    App.environment=config.MyExpress.Context.Environment;
    App.features=config.Features;
    App.signatureType=config.SignatureType;
    if(!App.isCompilationGoing){
      document.title="wDesk"
      console.log(config);
      const keycloak = new Keycloak({
        realm:config.MyExpress.Auth.Keycloak.realm,
        clientId:config.MyExpress.Auth.Keycloak.clientId,
        url:config.MyExpress.ClientAuth.keycloakBaseUrl
      });
      const authenticated = await keycloak.init({ onLoad: 'login-required' }).catch(()=>{console.error("Error while reaching for keycloak")});
      App.keycloak=keycloak;
      App.keycloakUser=(keycloak.tokenParsed as IKeycloakToken).preferred_username.split('@')[0];
      let companyFromDomain=App.environment=="live"?await ApiService.callToServer("anagrafica","getCompanyByDomain","v1",{
        "CompanyDomain":(keycloak.tokenParsed as IKeycloakToken).preferred_username.split('@')[1]
      }).catch(()=>{console.error("Not a correct tenant!")}):"Placeholder Company"

      this.setState({
        keycloak,
        isAuthenticated: authenticated?authenticated:false,
        isCorrectTenant:companyFromDomain?true:false,
        keycloakToken: keycloak.tokenParsed as IKeycloakToken // Dettagli dell'utente
      });
    }


  }

  public static handleLogout = () => {
    App.keycloak?.logout();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  // Creazione del Context
  private sideBarChange(value: string) {
    this.currentContent.current?.SetNewValue(value);
    if (this.clickToShowMenu) {
      const node: HTMLDivElement | null= this.currentSidebarDiv.current;
      node?.classList.add('d-none');
      node?.classList.add('d-md-block');
      this.clickToShowMenu = false;
    }
  }
  handleResize = () => {
    const node: HTMLDivElement | null= this.currentSidebarDiv.current;
    if (!node?.classList.contains('d-none')) {
      node?.classList.add('d-none');
      node?.classList.add('d-md-block');
    }
  };

  static async getConfig(){
    let config:ConfigInterface = await ApiService.callToServer("utils", "getConfiguration", "v1").catch(()=>{});
    return config;
  }

  render() {
    if(window.location.pathname=="/compilation"){
      App.isCompilationGoing=true;
      return (
          <div className="App">
            <div>
              <FirstCompilation></FirstCompilation>
            </div>
          </div>
      );

    }
    else if(window.location.pathname=="/tabletCompilation"){
      App.isCompilationGoing=true;
      return (
          <div className="App">
            <div>
              <TabletCompilation></TabletCompilation>
            </div>
          </div>
      );

    }
    else if(window.location.pathname=="/documentSign"){
      App.isCompilationGoing=true;
        return (
            <div className="App">
              <div>
                <DocumentSignPage></DocumentSignPage>
              </div>
            </div>
        );
    }
    else {
      if (this.state.keycloak) {
        if (this.state.isAuthenticated) {
          if (this.state.isCorrectTenant) {
            console.log(this.state.keycloakToken?.realm_access.roles)
            return (
                <div className="App">
                  <div>
                    {/* Assumi che Content prenda il nome dell'utente come prop */}
                    <Content ref={this.currentContent} name={this.state.keycloakToken?.given_name}/>
                  </div>
                </div>
            );
          } else {
            return (
                <div className="App">
                  <div>
                    <ErrorComponent></ErrorComponent>
                  </div>
                </div>
            );
          }
        } else {
          return <div>Unable to authenticate!</div>;
        }
      }
    }
    return <div>Loading...</div>;
  }

}

