import React from 'react';
import IDocumentSignPageProps from "./IDocumentSignPageProps";
import IDocumentSignPageState from './IDocumentSignPageState';
import DocumentSignPageState from "./DocumentSignPageState.json";
import "./DocumentSignPage.css";
import Header from "../Header/Header";
import wLazyForm from "@en/wlazyform";
import {ApiService} from "../../libs/ApiService";
import App from "../../App";
import IThumbnailOutput from "../Thumbnail/IThumbnailOutput";
import ENSoftService from "../../libs/ENSoftService";
import NotEnabled from "../NotEnabled/NotEnabled";
import DateUtility from "../../libs/dateUtility";


export default class DocumentSignPage extends React.Component<IDocumentSignPageProps, IDocumentSignPageState> {
  private templateGeneration:boolean=false;

  constructor(props: any) {
    super(props);
    this.state = DocumentSignPageState;
  }

  async componentDidMount(): Promise<void> {
    document.title="Firma documenti utente-wDesk"
    this.templateGeneration=(await App.getConfig()).Features.TemplateGeneration;
    if(this.templateGeneration){
      DocumentSignPageState.onScreen="waitingForServer";
      this.setState(DocumentSignPageState);
      let result:{
        userTaxCode:string,
        practiceID:string,
        documentsToSign:string[],
        callback:any,
        redirectURL:any
      }=await ApiService.callToServer("integration","checkLinkValidity","v1",{
        url:window.location.href
      }).catch(this.onLinkNotCorrect);
      if(!result){
        DocumentSignPageState.onScreen="linkNotValid";
        this.setState(DocumentSignPageState);
        return;
      }
      let practiceAlreadyCompleted=await ApiService.callToServer("documenti","getPracticeById","v1",{
        PracticeID:result.practiceID
      }).catch(this.onLinkNotCorrect);
      if(practiceAlreadyCompleted && typeof practiceAlreadyCompleted=='boolean' ){
        DocumentSignPageState.onScreen="practiceAlreadyDone"
        this.setState(DocumentSignPageState)
      }
      else{
        if(result){
          DocumentSignPageState.taxCode=result.userTaxCode;
          DocumentSignPageState.practiceID=result.practiceID;
          DocumentSignPageState.callback=result.callback;
          DocumentSignPageState.redirectURL=result.redirectURL;
          let practiceDocuments:{
            "documents":[
              {
                "ID": string,
                "DocumentType": string,
                "Name": any,
                "Description":any,
                "Document": string,
                "State": string
              }
            ],
            "user":{
              "CF":string,
              "FirstName": string,
              "LastName":string,
              "DateOfBirth": string,
              "UserAddress": {
                "StreetAddress": string,
                "City": string,
                "Province": string,
                "PostalCode": string
              }

            }
          }=await ApiService.callToServer("documenti","getDocumentsFromPractice","v1",{
            PracticeID:result.practiceID
          }).catch(this.onLinkNotCorrect);
          if(practiceDocuments){
            DocumentSignPageState.documents=[];
            DocumentSignPageState.preCompiledItems=[];
            for(let i=0;i<practiceDocuments.documents.length;i++){
              let documentDownloaded:{
                "document":string
              }=await ApiService.callToServer("documenti","downloadPracticeDocumentByPath","v1",{
                DocumentPath:practiceDocuments.documents[i].Document,
                CompanyName:App.companyName
              }).catch(this.onLinkNotCorrect);
              DocumentSignPageState.documents.push({
                id:practiceDocuments.documents[i].ID,
                title:practiceDocuments.documents[i].Name,
                pathPreview:"",
                pathPdf:"",
                selected:false,
                signedPDF:"",
                pdfOriginal:documentDownloaded.document
              })

            }
            let userCompilations:{
              "compilations":
                  [{
                    "ID": string,
                    "Company_ID": string,
                    "Template_ID": string,
                    "TaxCode": string,
                    "Data": any,
                    "State": string
                  }]
            }=await ApiService.callToServer("documenti","getUserCompilations","v1",{
              CompanyName:App.companyName,
              TaxCode:result.userTaxCode
            }).catch(this.onLinkNotCorrect);
            for(let i=0;i<userCompilations.compilations.length;i++){
              let idTemplate=1;
              if(userCompilations.compilations[i].State=="COMPILED"){
                let templateDetail=await ApiService.callToServer("anagrafica","getTemplateById","v1",{
                  "ID":userCompilations.compilations[i].Template_ID
                });
                DocumentSignPageState.preCompiledItems.push({
                  id: idTemplate.toString(),
                  title: templateDetail.Name+": "+DateUtility.convertDate(templateDetail.CreationDate.toString()),
                  pathPreview: "",
                  pathPdf: userCompilations.compilations[i].Template_ID,
                  selected: false,
                  pdfOriginal: "",
                  compilationID:userCompilations.compilations[i].ID,
                  data:userCompilations.compilations[i].Data
                })
                idTemplate++;
              }
            }
            this.setState(DocumentSignPageState);
            if(this.state.preCompiledItems.length == 0){
              DocumentSignPageState.onScreen="signingDocuments"
              ENSoftService.Init(this.props,this.handleDocumentCompleted,this.handleDocumentAborted,this.handleTabletConnection,this.handleTabletClose);
            }
            else{
              DocumentSignPageState.onScreen="preCompilationSelection"
            }
            this.setState(DocumentSignPageState);
            console.log(this.state.documents);
          }
          else{
            this.onLinkNotCorrect("Cannot retrieve practice documents")
            DocumentSignPageState.onScreen="linkNotValid";
            this.setState(DocumentSignPageState);
          }
        }
        else{
          this.onLinkNotCorrect("Data is not correct")
          DocumentSignPageState.onScreen="linkNotValid";
          this.setState(DocumentSignPageState);
        }
      }
    }
  }

  onLinkNotCorrect(error:any){
    console.error(error);
  }

  componentWillUnmount(): void {
    document.title="wDesk"
  }
  private async abortPractice() {
    DocumentSignPageState.onScreen = "waitingForServer";
    this.setState(DocumentSignPageState);
    for (let i = 0; i < this.state.documents.length; i++) {
      let documentData = {
        "PracticeID": this.state.practiceID,
        "DocumentSigned": this.state.documents[i].signedPDF != "" ? this.state.documents[i].signedPDF : this.state.documents[i].pdfOriginal,
        "DocumentID": this.state.documents[i].id
      }
      const res = await ApiService.callToServer("documenti", "uploadDocumentIntoPractice", "v1", documentData);
      const docUpdatePath = {
        DocumentID: this.state.documents[i].id,
        Document: res.DocumentPath
      }
      await ApiService.callToServer("documenti", "updateDocumentPath", "v1", docUpdatePath)
      const docUpdate = {
        DocumentID: this.state.documents[i].id,
        State: "ABORTED"
      }
      await ApiService.callToServer("documenti","updateDocumentState","v1",docUpdate)
    }
    await ApiService.callToServer("documenti","updatePracticeStatus","v1",{PracticeID:this.state.practiceID,State:"ABORTED"})
    ENSoftService.CloseConnection();
    if(this.state.callback){
      this.giveCallback(this.state.callback,false,`Practice ${this.state.practiceID} aborted!`)
    }
    document.title=`Pratica ${this.state.practiceID} ABORTED-wDesk`
    DocumentSignPageState.onScreen = "aborted";
    this.setState(DocumentSignPageState);
  }

  handleTabletConnection=async (payload:boolean)=>{
    if(payload){
      (document.getElementById("signDocumentBtn") as HTMLButtonElement).disabled=false;
    }
    else{
      (document.getElementById("signDocumentBtn") as HTMLButtonElement).disabled=true;
      let response=window.confirm("Non è stato rilevato alcun tablet con il software di firma. Riprovare?");
      if(response){
        ENSoftService.Init(this.props,this.handleDocumentCompleted,this.handleDocumentAborted,this.handleTabletConnection,this.handleTabletClose);
      }
    }
  }

  handleTabletClose=async (payload:number)=>{
    if(payload>1000){//errore con il software di firma
      (document.getElementById("signDocumentBtn") as HTMLButtonElement).disabled=true;
      let response=window.confirm("Il software di firma ha chiuso la connessione inaspettatamente. Stabilire una nuova connessione?");
      if(response){
        ENSoftService.Init(this.props,this.handleDocumentCompleted,this.handleDocumentAborted,this.handleTabletConnection,this.handleTabletClose);
      }
    }
  }

  handleDocumentCompleted = async (payload: any) => {
    // Esegui l'azione desiderata qui con il payload ricevuto da onDocumentCompleted
    console.log('Documento completato:', payload);
    this.state.documents[this.state.docIndex].signedPDF = payload['DocumentSigned'];
    if((this.state.docIndex+1)==this.state.documents.length){//ultimo elemento
      await ApiService.callToServer("documenti","updatePracticeStatus","v1",{PracticeID:this.state.practiceID,State:"SIGNED"})
      const docUpdate={
        DocumentID:this.state.documents[this.state.docIndex].id,
        State: "SIGNED"
      }
      await ApiService.callToServer("documenti","updateDocumentState","v1",docUpdate)
      this.setState(DocumentSignPageState);
      ENSoftService.CloseConnection();
      for(const element of DocumentSignPageState.documents) {
        let documentData = {
          "PracticeID": this.state.practiceID,
          "DocumentSigned": element.signedPDF,
          "DocumentID": element.id
        }
        const res = await ApiService.callToServer("documenti", "uploadDocumentIntoPractice", "v1", documentData);
        const docUpdatePath = {
          DocumentID: element.id,
          Document: res.DocumentPath
        }
        await ApiService.callToServer("documenti", "updateDocumentPath", "v1", docUpdatePath)
        const docUpdate = {
          DocumentID: element.id,
          State: "ARCHIVIED"
        }
        await ApiService.callToServer("documenti","updateDocumentState","v1",docUpdate)
      }
      await ApiService.callToServer("documenti","updatePracticeStatus","v1",{PracticeID:this.state.practiceID,State:"ARCHIVIED"})
      DocumentSignPageState.onScreen="finishSign"
      this.setState(DocumentSignPageState);
      document.title=`Pratica ${this.state.practiceID} COMPLETED-wDesk`
      if(this.state.callback){
        this.giveCallback(this.state.callback,true,`Practice ${this.state.practiceID} completed!`)
      }
      if(this.state.redirectURL){
        window.location.href=this.state.redirectURL
      }
    }
    else{
      const docUpdate={
        DocumentID:this.state.documents[this.state.docIndex].id,
        State: "SIGNED"
      }
      await ApiService.callToServer("documenti","updateDocumentState","v1",docUpdate)
      DocumentSignPageState.docIndex++
      this.setState(DocumentSignPageState);
      await this.handleButtonClick();
    }

  };
  handleDocumentAborted = async () => {
    await this.abortPractice();
  };

  giveCallback(url:string, success:boolean, message:string){
    ApiService.callToServer("integration","executeCallback","v1",{
      url:url,
      success:success,
      message:message
    }).then((msg)=>{
      console.log("Callback successful: "+msg);
    }).catch((err)=>{
      console.error(err);
    })
  }

  handleButtonClick = async () => {
    DocumentSignPageState.onScreen="waitingForServer";
    this.setState(DocumentSignPageState);
    console.log("lancio startSign sul file " + this.state.documents[this.state.docIndex].title);
    await ApiService.callToServer("documenti","updatePracticeStatus","v1",{PracticeID:this.state.practiceID,State:"SIGNING"})
    if(App.signatureType){
      await ENSoftService.StartSign(this.state.documents[this.state.docIndex].pathPdf, (App.signatureType.FES_Sign && !App.signatureType.FEA_Sign),this.state.documents[this.state.docIndex].pdfOriginal);
    }
    else{
      console.error("Cannot use a signature type!");
    }

  };

  render() {

    console.log("Entrato nel render");
    return (
        <div>
          {this.templateGeneration && (
            <div>
            <Header logout={() => {}}></Header>
    <div className="scrollable-content mt-5 pt-3">
      {this.state.onScreen=="preCompilationSelection" && this.state.preCompiledItems.length != 0 && (
          <div>
            <div className="card mb-2">
              <div className="card-header" id="headingOne">
                <h5 className="mb-0">
                  Compilazioni Utente
                </h5>
              </div>
              <div className="card-body p-1">
                <div className="list-header d-flex justify-content-between align-items-center px-3">
                  <div className="col-8"><strong>Identificativo compilazione</strong></div>
                  <div className="col-4 text-end"><strong>Data</strong></div>
                </div>
                <ul className="list-group list-group-flush">
                  {this.state.preCompiledItems.map((item, index) => (
                      <li key={index}
                          className={`list-group-item document ${item.selected ? 'select' : ''}`}
                          onClick={() => this.toggleSelect(item.title)}>
                        <div className="col-8"><i className="fa fa-file-pdf"></i>{item.title}</div>
                        <div className="col-4 text-end">27/02/2023</div>
                      </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="fixed-bottom footer-content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col text-start">
                    <button className="btn btn-secondary" onClick={async () => {
                      await this.abortPractice()
                    }}>Annulla
                    </button>
                  </div>
                  <div className="col text-end">
                    <button className="btn btn-primary" disabled={!this.itemSelect}
                            onClick={() => this.finishCompilation()}>Continua
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
      )}
      {this.state.onScreen == "preCompilationSelection" && this.state.preCompiledItems.length == 0 && (
          <div>
            <h3>L'utente con il codice fiscale fornito non ha compilazioni in sospeso</h3>
          </div>
      )}
      {this.state.onScreen === "linkNotValid" && (
          <div>
            <h3>Il link fornito non è corretto, impossibile proseguire.</h3>
          </div>
      )}
      {this.state.onScreen === "signingDocuments" && (
          <div className='afterIdocsBar FirmaTabletDiv'>

            <div className="scrollable-content pt-3">
              <div className="card mb-2">
                <div className="card-header" id="headingOne">
                  <h5 className="mb-0">
                    <button className="btn btn-link" type="button">
                      Elenco documenti pronti per la firma
                    </button>
                  </h5>
                </div>
                <div className="card-body p-1">
                  <div className="list-header d-flex justify-content-between align-items-center px-3">
                    <div className="col-12">
                      <strong>{this.state.documents.length > 1 ? "Nome Documento" : "Nome Documenti"}</strong>
                    </div>
                  </div>
                  <ul className="list-group list-group-flush">
                    {this.state.documents.map((pdfDocuments) => {
                      return (
                          <li className="list-group-item">
                            <div className="col-12"><i
                                className="fa fa-file-pdf"></i> {pdfDocuments.title}
                            </div>
                          </li>
                      )
                    })}
                  </ul>
                </div>
              </div>
            </div>

            <div className="fixed-bottom footer-content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col text-start">
                    <button className="btn btn-secondary"
                            onClick={async () => await this.abortPractice()}>Annulla
                    </button>
                  </div>
                  <div className="col text-end">
                    <button className="btn btn-primary" id="signDocumentBtn"
                            onClick={this.handleButtonClick}>Firma Ora
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
      )}
      {this.state.onScreen === "waitingForServer" && (
          <div id="overlay">
            <div id="overlay-content">
              <div className="loader"></div>
              <p className="text-light mt-3 fs-4">Caricamento...</p>
            </div>
          </div>)}
      {this.state.onScreen === "aborted" && (
          <h3>La pratica è stata annullata. Si può chiudere la pagina</h3>
      )}
      {this.state.onScreen === "suspended" && (
          <h3>La pratica è stata sospesa. Si può chiudere la pagina</h3>
      )}
      {this.state.onScreen === "finishSign" && (
          <h3>La pratica è stata completata con successo. Si può chiudere la pagina</h3>
      )}
      {this.state.onScreen === "practiceAlreadyDone" && (
          <h3>La pratica è già stata completata o annullata e non può più essere modificata. Si può chiudere la pagina</h3>
      )}
      {(this.state.onScreen=="preCompilationSelection" || this.state.onScreen=="compilation") && (
          <div>
            <div className="no-select afterIdocsBar">
              <div className="scrollable-content pt-3">
                <div id='generatedFormPlaceholder' className='lazyFormed'>

                </div>
              </div>
            </div>
          </div>
      )}
      {(this.state.onScreen=="compilation") && (
          <div>
            <div className="no-select afterIdocsBar">
              <div className="fixed-bottom footer-content">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col text-start">
                      <button className="btn btn-secondary" onClick={async () => {
                        await this.abortPractice()
                      }}>Annulla
                      </button>
                    </div>
                    <div className="col text-end">
                      <button className="btn btn-primary" id="compilingFormBtn" onClick={() => {
                        document.getElementById("btnSendForm")?.click();
                      }}>Invia
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      )}
    </div>
  </div>
          )}
          {!this.templateGeneration && (
              <NotEnabled></NotEnabled>
          )}

        </div>
    )
  }

  toggleSelect = async (title: string) => {
    let oneSelect = false;
    let eleSelected: IThumbnailOutput | null = null;


    for (let indice = 0; indice < DocumentSignPageState.preCompiledItems.length; indice++) {
      const elemento = DocumentSignPageState.preCompiledItems[indice];
      const select = (elemento.title.toLowerCase() === title.toLowerCase());
      elemento.selected = select;
      if (select) {
        eleSelected = elemento as {     id: string,     title: string,     compilationID:string ,pathPreview: string,     pathPdf: string,     selected: boolean,     pdfOriginal: string,     data: any };
      }

      this.setState(DocumentSignPageState)
    }
  }

  get itemSelect(): any {
    const selectedItem = DocumentSignPageState.preCompiledItems.find(elemento => elemento.selected);
    if (selectedItem) {
      return selectedItem;
    }
    return null;
  }

  private finishCompilation() {
    const selectedItem = DocumentSignPageState.preCompiledItems.find(elemento => elemento.selected);
    for(let i=0;i<DocumentSignPageState.preCompiledItems.length;i++){
      if(DocumentSignPageState.preCompiledItems[i]==selectedItem){
        DocumentSignPageState.compilationIndex=i;
      }
    }
    DocumentSignPageState.onScreen="compilation"
    this.setState(DocumentSignPageState);
    this.templateCompilation()
  }

  private templateCompilation(){

    const targetElement=document.getElementById("generatedFormPlaceholder");
      if(targetElement){
        const config={ attributes: true, childList: true, subtree: true };
        const mutationCallback=(mutationList:any,observer:any)=>{
          if(document.getElementById("generatedForm")){
            if(this.state.preCompiledItems[this.state.compilationIndex].data){
              this.state.preCompiledItems[this.state.compilationIndex].data.forEach((compiledData:{id:string,value?:string,checked?:string}) => {
                if(document.getElementById(compiledData.id) as HTMLInputElement || document.getElementById(`${compiledData.id}Yes`) as HTMLInputElement){
                  if(compiledData.value){
                    (document.getElementById(compiledData.id) as HTMLInputElement).value=compiledData.value;
                    (document.getElementById(compiledData.id) as HTMLInputElement).dispatchEvent(new InputEvent("input"));
                  }
                  if(compiledData.checked) {
                    if(document.getElementById(compiledData.id) as HTMLInputElement){
                      (document.getElementById(compiledData.id) as HTMLInputElement).checked=compiledData.checked==="true";
                      (document.getElementById(compiledData.id) as HTMLInputElement).dispatchEvent(new InputEvent("input"));
                    }
                    else{
                      compiledData.checked==="true"?(document.getElementById(`${compiledData.id}Yes`) as HTMLInputElement).checked=true:(document.getElementById(`${compiledData.id}No`) as HTMLInputElement).checked=true;
                      compiledData.checked==="true"?(document.getElementById(compiledData.id+'Yes') as HTMLInputElement).dispatchEvent(new InputEvent("input")):(document.getElementById(compiledData.id+'No') as HTMLInputElement).dispatchEvent(new InputEvent("input"));
                    }
                  }
                }
              })
            }
          }
        }
        const observer=new MutationObserver(mutationCallback);
        observer.observe(targetElement,config);
      }

    let [language,company,template,vert]=this.state.preCompiledItems[this.state.compilationIndex].pathPdf.split('/');
    wLazyForm.getForm(language,company,template,false,true,false,true,false,'',false,'wLazyDefaultAccess','asdghjkl').then(async (data)=>{
      let newDocument: {
        "id": string,
        "title": string,
        "pathPreview": string,
        "pathPdf": string,
        "selected": boolean,
        "pdfOriginal": string,
        "signedPDF": string
      }={
        id:this.state.preCompiledItems[this.state.compilationIndex].title,
        title:this.state.preCompiledItems[this.state.compilationIndex].title,
        pathPreview:"",
        pathPdf:"",
        selected:false,
        pdfOriginal:data,
        signedPDF:""
      };

      await ApiService.callToServer("documenti","updatePracticeStatus","v1",{PracticeID:this.state.practiceID,State:"SIGNABLE"})
      let config=await App.getConfig()
      let signatureType=config.SignatureType.FES_Sign?"FES":config.SignatureType.FEA_Sign?"FEA":null;
      const docInput={
        DocumentType:"PDF",
        Name:this.state.preCompiledItems[this.state.compilationIndex].title,
        Document: "/",
        State: "READY",
        Practice_ID: this.state.practiceID,
        UserCF: this.state.taxCode,
        CompanyName:App.companyName,
        SignatureType:signatureType
      }
      const res:{DocumentID:string}=await ApiService.callToServer("documenti","insertDocumentIntoPractice","v1",docInput)
      newDocument.id=res.DocumentID;
      let documentData={
        "PracticeID": this.state.practiceID,
        "DocumentSigned":newDocument.pdfOriginal,
        "DocumentID":res.DocumentID
      }
      const resUpload=await ApiService.callToServer("documenti","uploadDocumentIntoPractice","v1",documentData);
      const docUpdatePath={
        DocumentID:res.DocumentID,
        Document: resUpload.DocumentPath
      }
      await ApiService.callToServer("documenti","updateDocumentPath","v1",docUpdatePath)
      if(this.state.preCompiledItems[this.state.compilationIndex].data){
        await ApiService.callToServer("documenti","updateUserCompilation","v1",{ID:this.state.preCompiledItems[this.state.compilationIndex].title,State:"CONFIRMED"})
      }
      DocumentSignPageState.documents.push(newDocument);
      DocumentSignPageState.onScreen="signingDocuments";
      console.log(DocumentSignPageState.documents)
      ENSoftService.Init(this.props,this.handleDocumentCompleted,this.handleDocumentAborted,this.handleTabletConnection,this.handleTabletClose);
      this.setState(DocumentSignPageState)
    }).catch((err)=>{
      console.error(err);
    });
  }
}

